import { Button, ButtonVariant, FaIcon } from '@in/component-library';
import {
  ColumnDef,
  ColumnFiltersState,
  SortingState,
  flexRender,
  getCoreRowModel,
  getFacetedRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  useReactTable,
} from '@tanstack/react-table';
import classNames from 'classnames';
import React, { useMemo, useState } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import Table from 'src/components/Table/Table';
import { useDebounce, useLocalStorage } from 'usehooks-ts';
import { DataTableV3PrimaryAction, DataTableV3Props, DataTableV3SecondaryAction } from '../types';
import { createSelectionColumn } from '../utils';
import DataTableColumnEditor from './DataTableColumnEditor';
import DataTableExcelExportButton from './DataTableExcelExportButton';
import DataTableGlobalFilter from './DataTableGlobalFilter';
import DataTablePagination from './DataTablePagination';
import DataTableRowsSelectedText from './DataTableRowsSelectedText';
import PrimaryActions from './PrimaryActions';
import { DataTableV3LocalStoragePrefixes } from '../constants/localStorage';
import '../../../styles/style.scss';
import '../style.scss';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/pro-light-svg-icons';

const defaultPageSizeOptions = [5, 10, 25, 50, 100];

export const DataTableV3 = <TData extends object>({
  id,

  data,
  columns,
  actions,
  state: defaultState,
  pageCount,

  disableSelection = false,
  disablePagination = false,
  disableColumnEditor = false,
  disableGlobalFilter = false,
  disableExport = false,
  autoResetPageIndex = true,

  primaryActionLocation = 'top',

  pageSizeOptions = defaultPageSizeOptions,
  hideFilterText = false,
  filtersActive,
  pageSize = undefined,
  overridePaginationTotalNumber = undefined,
  paginationListeners = undefined,

  enableRowSelection,
  disabledRowTitle,
  showRowsSelectedText = false,
  rowsSelectedSingularTranslation = undefined,
  rowsSelectedPluralTranslation = undefined,
  texts = undefined,

  customColumnFilters = undefined,
  columnFilters: defaultColumnFilters,
  sorting: defaultSortingState,
}: DataTableV3Props<TData>) => {
  const { t: tTableTitles } = useTranslation('tableTitles');
  const { t: tCommon } = useTranslation();

  const [globalFilter, setGlobalFilter] = useState<string>('');
  const [sortingState, setSortingState] = useLocalStorage<SortingState>(
    `${DataTableV3LocalStoragePrefixes.sortingState}${id}`,
    defaultSortingState ?? [],
  );
  const [columnFilters, setColumnFilters] = useState<ColumnFiltersState>(defaultColumnFilters ?? []);

  const resolvedColumnFilters = useMemo(() => {
    if (defaultColumnFilters) {
      return defaultColumnFilters;
    }

    return columnFilters;
  }, [columnFilters, defaultColumnFilters]);

  const debouncedGlobalFilter = useDebounce(globalFilter, 150);

  const [rowSelection, setRowSelection] = useState({});
  const [columnVisibility, setColumnVisibility] = useLocalStorage<Record<string, boolean>>(
    `${DataTableV3LocalStoragePrefixes.columnVisibility}${id}`,
    columns.reduce(
      (acc, column) => {
        if (column.id) {
          acc[column.id] = column.meta?.hide === true ? false : true;
        }

        return acc;
      },
      {} as Record<string, boolean>,
    ),
  );
  const [storagePageSize, setStoragePageSize] = useLocalStorage<number>(
    `${DataTableV3LocalStoragePrefixes.pageSize}${id}`,
    pageSize ? pageSize : pageSizeOptions[0],
  );

  const selectionColumn = createSelectionColumn<TData>({
    disabledRowTitle,
  });

  const alteredColumns = React.useMemo(() => {
    const cols: ColumnDef<TData, any>[] = columns.map((column) => {
      return {
        ...column,
        sortingFn: column.sortingFn ?? 'text',
      };
    });

    if (!disableSelection) {
      cols.unshift(selectionColumn);
    }

    return cols;
  }, [columns, disableSelection, selectionColumn]);

  const table = useReactTable<TData>({
    data,
    columns: alteredColumns,
    initialState: {
      pagination: disablePagination
        ? undefined
        : {
            pageSize: storagePageSize,
          },
    },
    state: {
      ...defaultState,
      rowSelection: rowSelection,
      columnVisibility: disableColumnEditor ? undefined : columnVisibility,
      sorting: sortingState,
      globalFilter: debouncedGlobalFilter,
      columnFilters: resolvedColumnFilters,
    },
    pageCount: pageCount,
    autoResetPageIndex: autoResetPageIndex,

    enableRowSelection: enableRowSelection,

    onSortingChange: setSortingState,
    onGlobalFilterChange: setGlobalFilter,
    onRowSelectionChange: setRowSelection,
    onColumnVisibilityChange: setColumnVisibility,
    onColumnFiltersChange: setColumnFilters,

    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    getSortedRowModel: getSortedRowModel(),
    getFilteredRowModel: getFilteredRowModel(),
    getFacetedRowModel: getFacetedRowModel(),
  });

  async function handleActionOnClick(
    action: DataTableV3PrimaryAction<TData> | DataTableV3SecondaryAction<TData>,
  ) {
    const rows = table.getSelectedRowModel().flatRows.map((row) => row.original);

    const promiseOrResult = action.onClick?.(rows);
    if (promiseOrResult instanceof Promise) {
      const resetSelected = await promiseOrResult;
      if (resetSelected === true) {
        table.toggleAllRowsSelected(false);
      }
    } else {
      if (promiseOrResult === true) {
        table.toggleAllRowsSelected(false);
      }
    }
  }

  type ColumnHeaderProps = {
    canSort: boolean;
    sortHandler: () => undefined | ((event: unknown) => void);
    children?: React.ReactNode | React.ReactNode[];
  };

  const ColumnHeader = ({ canSort, sortHandler, children }: ColumnHeaderProps) => {
    return (
      <>
        {canSort ? (
          <button
            className={classNames(
              'display--flex justify-content--flex-start align-items--center gap--2 clickable-wrapper user-select--none',
            )}
            onClick={sortHandler()}
          >
            {children}
          </button>
        ) : (
          <div>{children}</div>
        )}
      </>
    );
  };

  return (
    <>
      <div className="display--flex align-items--flex-start justify-content--flex-start gap--2 margin-bottom--2">
        {primaryActionLocation === 'top' && <PrimaryActions actions={actions} table={table} />}
      </div>

      <DataTableGlobalFilter
        value={globalFilter}
        onChange={(e) => {
          table.toggleAllRowsSelected(false);
          setGlobalFilter(e.currentTarget.value);
          table.setPageIndex(0);
        }}
        disabled={disableGlobalFilter}
        texts={texts}
      />

      {customColumnFilters && <>{customColumnFilters}</>}

      <div className="display--flex align-items--center justify-content--space-between">
        <div className="display--flex align-items--center gap--2">
          {actions?.secondary?.map((action, index) => (
            <Button
              key={index}
              type="button"
              iconName={action.icon}
              variant={ButtonVariant.Link}
              isLoading={action.isLoading}
              disabled={action.disabled}
              onClick={() => handleActionOnClick(action)}
            >
              {action.label}
            </Button>
          ))}
        </div>

        <div className="display--flex align-items--center gap--2">
          <DataTableColumnEditor
            table={table}
            disabled={disableColumnEditor}
            disableSelection={disableSelection}
          />

          {!disableExport && <DataTableExcelExportButton table={table} />}
        </div>
      </div>
      {!hideFilterText && (
        <p className={filtersActive ? 'visibility--visible' : 'visibility--hidden'}>
          {table.getFilteredRowModel().flatRows.length === 1 ? (
            tCommon('oneResult')
          ) : (
            <Trans
              t={tCommon}
              i18nKey="numberOfResults"
              values={{ resultsCount: table.getFilteredRowModel().flatRows.length }}
            />
          )}
        </p>
      )}

      <DataTableRowsSelectedText
        table={table}
        showRowsSelectedText={showRowsSelectedText}
        singularTranslation={rowsSelectedSingularTranslation}
        pluralTranslation={rowsSelectedPluralTranslation}
      />

      <div className="display--block overflow-x--scroll margin-bottom--2">
        <Table
          className={classNames({
            'table--selectable': !disableSelection,
          })}
        >
          <Table.Head>
            {table.getHeaderGroups().map((headerGroup) => (
              <Table.Row key={headerGroup.id}>
                {headerGroup.headers.map((header) => {
                  return (
                    <Table.Header
                      key={header.id}
                      colSpan={header.colSpan}
                      className={`${
                        !header.column.getCanSort()
                          ? ''
                          : header.column.getIsSorted()
                            ? 'active-sort-column'
                            : 'column-sort-button'
                      }`}
                      style={{
                        width: header.column.columnDef.size,
                        maxWidth: header.column.columnDef.maxSize,
                        minWidth: header.column.columnDef.minSize,
                        wordWrap: 'break-word',
                      }}
                    >
                      {header.isPlaceholder ? null : (
                        <ColumnHeader
                          canSort={header.column.getCanSort()}
                          sortHandler={header.column.getToggleSortingHandler}
                        >
                          {header.column.columnDef.id?.startsWith('__') ? (
                            <div>{flexRender(header.column.columnDef.header, header.getContext())}</div>
                          ) : (
                            <span>
                              {tTableTitles(
                                (typeof header.column.columnDef.header === 'string'
                                  ? header.column.columnDef.header
                                  : (header.column.columnDef.id ?? '')) as any,
                                {
                                  defaultValue: flexRender(
                                    header.column.columnDef.header,
                                    header.getContext(),
                                  ),
                                },
                              )}
                            </span>
                          )}

                          {header.column.getCanSort() && (
                            <div className="smaller-icon">
                              {{
                                asc: (
                                  <FaIcon
                                    icon={icon({ prefix: 'fal', iconName: 'arrow-up' }) as IconDefinition}
                                    aria-label="Sortert stigende"
                                  />
                                ),
                                desc: (
                                  <FaIcon
                                    icon={icon({ prefix: 'fal', iconName: 'arrow-down' }) as IconDefinition}
                                    aria-label="Sortert synkende"
                                  />
                                ),
                              }[header.column.getIsSorted() as string] ?? (
                                <FaIcon
                                  icon={
                                    icon({ prefix: 'fal', iconName: 'arrow-up-arrow-down' }) as IconDefinition
                                  }
                                  aria-label="Sorter kolonne"
                                />
                              )}
                            </div>
                          )}
                        </ColumnHeader>
                      )}
                    </Table.Header>
                  );
                })}
              </Table.Row>
            ))}
          </Table.Head>
          <Table.Body>
            {table.getRowModel().rows.length > 0 ? (
              table.getRowModel().rows.map((row) => (
                <Table.Row
                  key={row.id}
                  className={classNames({
                    selected: row.getIsSelected(),
                  })}
                >
                  {row.getVisibleCells().map((cell) => (
                    <Table.Cell
                      key={cell.id}
                      className="opportunity-table__cell"
                      style={{
                        width: cell.column.columnDef.size,
                        maxWidth: cell.column.columnDef.maxSize,
                        minWidth: cell.column.columnDef.minSize,
                        wordWrap: 'break-word',
                      }}
                    >
                      {flexRender(cell.column.columnDef.cell, cell.getContext())}
                    </Table.Cell>
                  ))}
                </Table.Row>
              ))
            ) : (
              <Table.Row>
                <Table.Cell colSpan={table.getAllColumns().length} className="opportunity-table__cell">
                  {tCommon('noResults')}
                </Table.Cell>
              </Table.Row>
            )}
          </Table.Body>
        </Table>
      </div>

      <DataTablePagination
        table={table}
        disabled={disablePagination}
        pageSizeOptions={pageSizeOptions}
        paginationListeners={paginationListeners}
        overridePaginationTotalNumber={overridePaginationTotalNumber}
        setStoragePageSize={setStoragePageSize}
      />

      {primaryActionLocation === 'bottom' && <PrimaryActions actions={actions} table={table} />}
    </>
  );
};
