import React, { Suspense, useMemo } from 'react';

import { Layout } from './components/Layout/Layout';
import { LoadingPage } from './components/LoadingPage/LoadingPage';

import { useUser, useAuth } from './features/authorization';

import AppLoading from './components/AppLoading/AppLoading';

import { useClusters } from 'src/features/cluster';
import useConfiguration from './hooks/use-configuration';
import RemoveTrailingSlash from './navigation/RemoveTrailingSlash';
import ErrorBoundary from './components/ErrorBoundary/ErrorBoundary';
import { useTranslation } from 'react-i18next';
import useEnvironment from './hooks/use-environment';

const AppRouter = React.lazy(() => import('./navigation'));

const App: React.FC = () => {
  const user = useUser();
  const auth = useAuth();
  const clusters = useClusters();
  const configuration = useConfiguration();
  const { t: tError } = useTranslation('error');

  const { isDevOrTest } = useEnvironment();

  const isLoading = useMemo(() => {
    return user.isLoading || auth.isLoading || clusters.isLoading || configuration.isLoading;
  }, [auth.isLoading, clusters.isLoading, configuration.isLoading, user.isLoading]);

  if (isLoading) {
    return <AppLoading />;
  }

  return (
    <ErrorBoundary message={tError('frontend.ErrorBoundaryTitle')} isDevOrTest={isDevOrTest}>
      <Layout user={user}>
        <Suspense fallback={<LoadingPage />}>
          <RemoveTrailingSlash />
          <AppRouter user={user} />
        </Suspense>
      </Layout>
    </ErrorBoundary>
  );
};

export default App;
