import React from 'react';
import { ToastContainer } from 'react-toastify';
import { BrowserRouter } from 'react-router-dom';
import { HelmetProvider } from 'react-helmet-async';
import { ReactQueryDevtools } from 'react-query/devtools';
import { QueryClientProvider, QueryClient } from 'react-query';

import { library } from '@fortawesome/fontawesome-svg-core';
import { IconDefinition } from '@fortawesome/fontawesome-common-types';
import {
  faClipboardListCheck,
  faClock,
  faSackDollar,
  faUsers,
  faHandshake,
  faUserPlus,
  faBadgePercent,
  faCalendarEdit,
  faClipboardList,
  faCalendarCheck,
  faCalendarClock,
  faPieChart,
  faMugHot,
  faPowerOff,
  faAddressCard,
  faShareNodes,
  faPaperPlane,
  faEnvelope,
  faEnvelopeOpen,
  faStickyNote,
  faShare,
  faHandHoldingBox,
  faDownToBracket,
  faGrid2Plus,
  faNoteSticky,
  faFileCircleCheck,
  faFolderOpen,
  faArrowUpRight,
  faArrowDownRight,
  faHorizontalRule,
  faBrowsers,
  faCircleXmark,
  faArrowRight,
  faFileExport,
  faMobile,
  faBuildings,
  faMemo,
  faListTimeline,
  faBoxArchive,
  faArrowLeft,
  faArchive,
  faMoneyCheckDollarPen,
  faChartMixed,
  faFileContract,
  faLocationDot,
  faTriangleExclamation,
  faMemoCircleInfo,
  faArrowUp,
  faArrowDown,
  faArrowUpArrowDown,
} from '@fortawesome/pro-light-svg-icons';
import { faScrubber, faMessageQuestion } from '@fortawesome/pro-regular-svg-icons';

import './i18n';
import '@in/kunde-raskavklaring-visning/dist/raskavklaring-visning.css';
import '@in/component-library/dist/component-library.css';
import './styles/style.scss';
import './styles/toastify.scss';

import App from './App';

import { ApiException } from './api/v2';

import { USER_SESSION_HAS_EXIRED, USER_STAUTS_LOGGED_IN } from './constants/hook-keys';

import { createRoot } from 'react-dom/client';
import SupportButton from './components/SupportButton/SupportButton';
import { toastIfValidationError } from './utils/validationErrorHandling';

library.add(
  faSackDollar as IconDefinition,
  faClock as IconDefinition,
  faClipboardListCheck as IconDefinition,
  faUsers as IconDefinition,
  faHandshake as IconDefinition,
  faUserPlus as IconDefinition,
  faScrubber as IconDefinition,
  faBadgePercent as IconDefinition,
  faCalendarEdit as IconDefinition,
  faClipboardList as IconDefinition,
  faCalendarCheck as IconDefinition,
  faCalendarClock as IconDefinition,
  faPieChart,
  faMugHot as IconDefinition,
  faPowerOff,
  faAddressCard,
  faShareNodes,
  faPaperPlane,
  faEnvelope,
  faEnvelopeOpen,
  faStickyNote,
  faShare,
  faHandHoldingBox,
  faDownToBracket,
  faGrid2Plus,
  faMessageQuestion,
  faNoteSticky as IconDefinition,
  faFileCircleCheck as IconDefinition,
  faFolderOpen as IconDefinition,
  faArrowUpRight as IconDefinition,
  faArrowDownRight as IconDefinition,
  faHorizontalRule as IconDefinition,
  faBrowsers as IconDefinition,
  faCircleXmark as IconDefinition,
  faArrowRight,
  faFileExport as IconDefinition,
  faMobile as IconDefinition,
  faBuildings as IconDefinition,
  faMemo as IconDefinition,
  faListTimeline as IconDefinition,
  faBoxArchive as IconDefinition,
  faArrowLeft as IconDefinition,
  faArchive as IconDefinition,
  faMoneyCheckDollarPen as IconDefinition,
  faChartMixed as IconDefinition,
  faFileContract as IconDefinition,
  faLocationDot as IconDefinition,
  faTriangleExclamation as IconDefinition,
  faMemoCircleInfo as IconDefinition,
  faArrowUp as IconDefinition,
  faArrowDown as IconDefinition,
  faArrowUpArrowDown as IconDefinition,
);

const retryStrategy =
  (maxFailureCount: number) =>
  (failureCount: number, error: unknown): boolean => {
    if (ApiException.isApiException(error)) {
      // Noen ganger så er status med stor bokstav, da denne ekstra sjekken
      if (
        error.status === 401 ||
        (error as any).Status === 401 ||
        error.status === 403 ||
        (error as any).Status === 403
      ) {
        return false;
      }
    }

    // Sender inn max feil, da vi ikke vil ha masse feil på mutations
    if (failureCount < maxFailureCount) {
      return true;
    }

    return false;
  };

const handleOnError = (error: unknown): void => {
  toastIfValidationError(error);
  if (ApiException.isApiException(error)) {
    if (error.status === 401 || (error as any).Status === 401) {
      const userStatusLoggedIn = queryClient.getQueryData(USER_STAUTS_LOGGED_IN);
      if (userStatusLoggedIn) {
        queryClient.setQueryData([USER_SESSION_HAS_EXIRED], true);
      }

      queryClient.setQueryData([USER_STAUTS_LOGGED_IN], false);
    }
  }
};

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnReconnect: false,
      refetchOnMount: false,
      retry: retryStrategy(3),

      onError: handleOnError,
    },
    mutations: {
      retry: retryStrategy(1),

      onError: handleOnError,
    },
  },
});

const baseUrl = document.getElementsByTagName('base')[0].getAttribute('href') as string;

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
  // Bruker strict mode for testing. kommentert ut nå fordi det per i dag ligger nå
  // fetch av data i useeffect og strict mode kjører mount og unmount to ganger som gjør at
  // <React.StrictMode>
  <QueryClientProvider client={queryClient}>
    <HelmetProvider>
      <BrowserRouter basename={baseUrl}>
        <App />
      </BrowserRouter>
    </HelmetProvider>

    <ToastContainer closeOnClick={false} position="top-right" theme="colored" />
    <ReactQueryDevtools />
    <SupportButton />
  </QueryClientProvider>,
  // </React.StrictMode>,
);
