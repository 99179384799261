import React from 'react';
import { HelpAndSupportCommonQuestionsSelection } from '../../constants/HelpAndSupportMenu';
import { Button } from '@in/component-library';
import { useTranslation } from 'react-i18next';
import CommunicationBetweenActors from './CommunicationBetweenActors';
import Referralprocess from './ReferralProcess';
import SideMenuNavButton from '../drawer-sidemenu/SideMenuNavButton';

const CommonQuestions: React.FC = () => {
  const { t: tCommon } = useTranslation();
  const { t: tHelpAndSupport } = useTranslation('onboarding');
  const [subpage, setSubpage] = React.useState<HelpAndSupportCommonQuestionsSelection>('none');

  if (subpage !== 'none') {
    return (
      <div className="help-and-support-common-questions__subpage">
        <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>

        {subpage === 'actorCommunication' && <CommunicationBetweenActors />}
        {subpage === 'referralprocess' && <Referralprocess />}

        <Button
          iconName="fa-light fa-arrow-left"
          iconPosition="left"
          size="medium"
          theme="neutral"
          variant="link"
          className="margin-top--3"
          onClick={() => setSubpage('none')}
        >
          {tCommon('previous')}
        </Button>
      </div>
    );
  }

  return (
    <div>
      <h2>{tHelpAndSupport('helpAndSupport.tab.CommonQuestions')}</h2>
      <SideMenuNavButton onClick={() => setSubpage('actorCommunication')}>
        {tHelpAndSupport('commonQuestions.Interplay.CommunicationBetweenActors')}
      </SideMenuNavButton>
      <SideMenuNavButton onClick={() => setSubpage('referralprocess')}>
        {tHelpAndSupport('commonQuestions.Interplay.Referralprocess')}
      </SideMenuNavButton>
    </div>
  );
};

export default CommonQuestions;
